<template>
    <div class="pt-10">
        <v-row>
            <v-col>
                <h1 class="d-flex align-center">
                    Spa Status <small class="ml-3" v-if="monitorId">({{ monitorId }})</small>
                    <v-dialog width="500">
                        <template v-slot:activator="{ props }">
                            <div v-bind="props" class="linkStateIndicator" :class="linkStateIndicatorClass"></div>
                        </template>

                        <template v-slot:default="{ isActive }">
                            <v-card title=" Connection Status" :subtitle="`client id: ${clientId ?? 'generating...'}`">
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <h3>Cloud</h3>
                                            <strong class="linkStateText" :class="wssLinkStateIndicatorClass">
                                                {{ cloudConnectionStatusText }}
                                            </strong>
                                        </v-col>
                                        <v-col>
                                            <h3>Gateway</h3>
                                            <strong class="linkStateText" :class="wssLinkStateIndicatorClass">
                                                {{ wssLinkStateText }}
                                            </strong>
                                        </v-col>
                                        <v-col>
                                            <h3>Spa</h3>
                                            <strong class="linkStateText" :class="spaLinkStateIndicatorClass">
                                                {{ spaLinkStateText }}
                                            </strong>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="snackBarText">
                                        <v-col>
                                            <p>error={{ snackBarText }};</p>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text="Close" @click="isActive.value = false" />
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                </h1>
            </v-col>
        </v-row>
        <v-row v-if="showLoading" class="my-10">
            <v-col class="text-center">
                <v-progress-circular indeterminate></v-progress-circular>
            </v-col>
        </v-row>

        <v-row v-if="connected && spaState">
            <v-col class="d-flex align-center">
                <v-btn v-for="pump of pumps" :key="pump.id" :color="colorForPumpState(pump)" class="mr-3 mb-3" @click="togglePump(pump)">
                    Pump {{ pump.id }}
                </v-btn>
                <v-btn
                    v-for="waterfall of waterfalls"
                    class="mr-3 mb-3"
                    :key="waterfall.id"
                    :color="colorForWaterfallState(waterfall)"
                    @click="toggleWaterfall(waterfall)"
                >
                    Waterfall {{ waterfall.id }}
                </v-btn>
                <v-btn v-for="light of lights" :key="light.id" :color="colorForLightState(light)" class="mr-3 mb-3" @click="toggleLight(light)">
                    Light {{ light.id }}
                </v-btn>
            </v-col>
            <v-col class="d-flex align-center">
                <v-text-field v-model.number="setPointF" label="Heater Set Point °F" clearable :disabled="!spaConnected" hide-details class="mr-3" />
                <v-btn @click="updateSetPoint" size="small">UPDATE</v-btn>
            </v-col>
        </v-row>

        <v-row v-if="connected && spaState">
            <v-col>
                <template v-if="heater !== undefined">
                    <h2>HEATER</h2>
                    <v-table class="text-left" density="compact">
                        <tbody>
                            <tr>
                                <th></th>
                                <th>°F</th>
                                <th>°C</th>
                            </tr>
                            <tr v-if="heater?.temperature_ !== undefined">
                                <th class="yellow">Displayed Temp.</th>
                                <td class="yellow">{{ round(celsiusToFahrenheit(heater?.temperature_)) }} °F</td>
                                <td class="yellow">{{ heater?.temperature_ }} °C</td>
                            </tr>
                            <tr v-else>
                                <th>Displayed Temp.</th>
                                <td>NOT VALID</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th>Heater SetPoint:</th>
                                <td>{{ heater?.setPoint !== undefined ? round(celsiusToFahrenheit(heater?.setPoint)) : '--' }} °F</td>
                                <td>{{ heater?.setPoint !== undefined ? heater?.setPoint : '--' }} °C</td>
                            </tr>
                            <tr>
                                <th>Min. SetPoint:</th>
                                <td>{{ round(celsiusToFahrenheit(heater?.minTemperatureSetPointC)) }} °F</td>
                                <td>{{ heater?.minTemperatureSetPointC }} °C</td>
                            </tr>
                            <tr>
                                <th>Max. SetPoint:</th>
                                <td>{{ round(celsiusToFahrenheit(heater?.maxTemperatureSetPointC)) }} °F</td>
                                <td>{{ heater?.maxTemperatureSetPointC }} °C</td>
                            </tr>
                            <tr>
                                <th>Heater State:</th>
                                <td>{{ heaterStatusText }}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </v-table>

                    <br />
                </template>

                <h2>Filter & Ecomony Mode</h2>
                <v-table class="text-left" density="compact">
                    <tbody>
                        <tr>
                            <th>Active Operation Mode:</th>
                            <td>{{ operationModeText }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
            <v-col>
                <h2>REMINDERS</h2>
                <v-table class="text-left" density="compact">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Days Remaining</th>
                            <th>Push Enabled</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- TODO: When available -->
                        <!-- <tr v-for="reminder of spaState.reminders" :key="reminder.type">
                            <td>{{ reminder.type }}</td>
                            <td>{{ reminder.daysRemaining }}</td>
                            <td>{{ reminder.pushEnabled }}</td>
                        </tr> -->
                    </tbody>
                </v-table>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import { useBreadCrumbsStore } from '@/stores/breadCrumbs'
import { useVesselStore } from '@/stores/vessel'
import { useAuth0 } from '@auth0/auth0-vue'
import { celsiusToFahrenheit, fahrenheitToCelsius, round } from '@geckoal/gecko-spa-api'
import {
    MessageTransporter,
    MqttTransporterStrategy,
    TransporterConnectionStatus,
    TransporterEvent,
    VesselClient,
    VesselStateEvent,
    type FlowZone,
    type LightingZone,
    type MqttSession,
    type TemperatureControlZone
} from '@geckoal/vessel-client'
import type { TransporterEventCallback } from '@geckoal/vessel-client/src/lib/messageHandler/transporterEventListener'
import {
    GatewayConnectionStatus,
    OperationMode,
    TemperatureControlZoneStatus,
    VesselConnectionStatus,
    type StaticSpaConfigV1,
    type StaticStateVesselV1
} from '@geckoal/web-models'
import { computed, onMounted, ref, watch, type Ref } from 'vue'
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router'

const vesselStore = useVesselStore()
const breadCrumbsStore = useBreadCrumbsStore()
const user = useAuth0().user

const spaState = ref<StaticStateVesselV1 | undefined>()

const cloudConnectionStatus = ref<TransporterConnectionStatus>(TransporterConnectionStatus.UNKNOWN)
const inboundMessages = ref<string[]>([])
const outboundMessages = ref<string[]>([])
const setPointF = ref<number | undefined>()
const snackBarText = ref<string | undefined>()
const shouldBeConnected = ref(true)
const clientId: Ref<string | undefined> = ref()
const isInConnection = ref(false)

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    vesselId: {
        type: Number,
        required: true
    }
})


const linkStateColors = {
    UNAVAILABLE: 'gray',
    OFFLINE: 'red',
    LOADING: 'orange',
    ONLINE: 'green'
}

let clientIdNeedsGeneration = true

let vesselClient: VesselClient | undefined = undefined
let spaConfig: StaticSpaConfigV1 | undefined = undefined

onMounted(async function init() {
    _updateBreadCrumb()
    await vesselStore.loadVessel(props.accountId, props.vesselId)
})

onBeforeRouteUpdate(() => {
    shouldBeConnected.value = true
})

onBeforeRouteLeave(async (_to, _from, next) => {
    shouldBeConnected.value = false // required or the watch will reconnect automatically when this component goes to the background
    await disconnectFromSpa()
    console.log('DISCONNECTED FROM SPA')
    next()
})

function _updateBreadCrumb() {
    breadCrumbsStore.$patch({
        items: [
            {
                text: `Account #${props.accountId}`,
                to: {
                    name: 'AccountDetails',
                    params: {
                        accountId: props.accountId
                    }
                }
            },
            {
                text: vesselStore.vessel?.name ?? `Vessel #${props.vesselId}`,
                to: {
                    name: 'VesselDetails',
                    params: {
                        accountId: props.accountId,
                        vesselId: props.vesselId
                    }
                }
            },
            {
                text: 'Spa Status'
            }
        ]
    })
}

/**
 * TEMPORARY FIX UNTIL THE CHANGE IS MADE ON THE CONFIG
 * @deprecated
 */
const dictionaryToObjectWithId = (dictionary: { [key: string]: any }) => {
    return Object.entries(dictionary).reduce((acc, [key, value]) => {
        return { ...acc, [key]: { ...value, configId: key } }
    }, {})
}

/**
 * TEMPORARY FIX UNTIL THE CHANGE IS MADE ON THE CONFIG
 * @deprecated
 */
const fixConfigIdFromConfig = (config: StaticSpaConfigV1): StaticSpaConfigV1 => {
    const newConfig = { ...config }

    if (newConfig.zones?.flow) {
        newConfig.zones.flow = dictionaryToObjectWithId(newConfig.zones.flow)
    }

    if (newConfig.zones?.temperatureControl) {
        newConfig.zones.temperatureControl = dictionaryToObjectWithId(newConfig.zones.temperatureControl)
    }

    if (newConfig.zones?.lighting) {
        newConfig.zones.lighting = dictionaryToObjectWithId(newConfig.zones.lighting)
    }

    return newConfig
}

async function fetchSpaConfiguration(accountId: number, monitorId: string): Promise<StaticSpaConfigV1> {
    const spaConfig = (await useApi().getAccountMonitorConfigurationV1({
        accountId,
        monitorId
    })) as unknown as StaticSpaConfigV1

    return fixConfigIdFromConfig(spaConfig)
}

async function fetchLiveStreamSession(monitorId: string) {
    const liveStreamSession = await useApi().getMonitorLiveStreamV2({ monitorId: monitorId })
    return liveStreamSession
}

const vessel = computed(() => {
    return vesselStore.vessel
})

const monitorId = computed(() => {
    return vessel.value?.monitor?.monitorId
})

const cloudConnectionStatusText = computed(() => {
    if (cloudConnectionStatus.value === TransporterConnectionStatus.CONNECTED) return 'Connected'
    if (cloudConnectionStatus.value === TransporterConnectionStatus.DISCONNECTED) return 'Disconnected'
    return 'Not Connected'
})

const wssLinkStateText = computed(() => {
    if (wssLinkState.value === GatewayConnectionStatus.CONNECTED) return 'Connected'
    if (wssLinkState.value === GatewayConnectionStatus.DISCONNECTED) return 'Disconnected'
    return 'Not Connected'
})

const spaLinkStateText = computed(() => {
    if (spaLinkState.value === VesselConnectionStatus.RUNNING) return 'Connected'
    if (spaLinkState.value === VesselConnectionStatus.RF_DISCONNECTED) return 'Disconnected'
    if (spaLinkState.value === VesselConnectionStatus.RF_IN_PAIRING) return 'Pairing'
    return 'Not Connected'
})

const heaterStatusText = computed(() => {
    if (heater.value === undefined) return 'Not Connected'
    switch (heater.value.status_) {
        case TemperatureControlZoneStatus.IDLE:
            return 'Idle'
        case TemperatureControlZoneStatus.HEATING:
            return 'Heating'
        case TemperatureControlZoneStatus.COOLING:
            return 'Cooling'
        case TemperatureControlZoneStatus.SYS_ERROR:
            return 'Temperature Reading Invalid'
        default:
            return 'Unknown'
    }
})

const operationModeText = computed(() => {
    if (spaState.value === undefined) return 'Unavailable'

    // loop through the operation modes and return the correct string
    for (const mode of Object.values(OperationMode)) {
        if (mode === spaState.value.features?.operationMode) {
            return OperationMode[mode].toString()
        }
    }
    return 'Unknown'
})

const pumps = computed(() => {
    const flowZonesConfig = spaConfig?.zones?.flow
    const flowZones = spaState.value?.zones?.flow

    if (flowZonesConfig === undefined || flowZones === undefined) return undefined

    return Object.values(flowZonesConfig)
        .filter((flow) => flow.waterfalls?.length === 0 || flow.waterfalls === undefined)
        .map((flow) => flowZones[flow.configId as string] as FlowZone)
        .sort((a, b) => a.id.localeCompare(b.id))
})

const lights = computed(() => {
    const lightingZonesConfig = spaConfig?.zones?.lighting
    const lighthingZones = spaState.value?.zones?.lighting

    if (lightingZonesConfig === undefined || lighthingZones === undefined) return undefined

    return Object.values(lightingZonesConfig).map((lighthing) => lighthingZones[lighthing.configId as string] as LightingZone)
})

const waterfalls = computed(() => {
    const flowZonesConfig = spaConfig?.zones?.flow
    const flowZones = spaState.value?.zones?.flow

    if (flowZonesConfig === undefined || flowZones === undefined) return undefined

    return Object.values(flowZonesConfig)
        .filter((flow) => (flow.waterfalls?.length ?? 0) > 0)
        .map((flow) => flowZones[flow.configId as string] as FlowZone)
})

const heater = computed(() => {
    const tempControlsConfig = spaConfig?.zones?.temperatureControl
    const tempControlZones = spaState.value?.zones?.temperatureControl

    if (tempControlsConfig === undefined || tempControlZones === undefined) return undefined

    return Object.values(tempControlsConfig)
        .map((tempCtrl) => tempControlZones[tempCtrl.configId as string] as TemperatureControlZone)
        .at(0)
})

const heaterSetPointC = computed(() => {
    return heater.value?.setPoint
})

const wssLinkState = computed(() => {
    return spaState.value?.connectivity_?.gatewayStatus
})

const connected = computed(() => {
    return wssLinkState.value === GatewayConnectionStatus.CONNECTED
})

const spaLinkState = computed(() => {
    return spaState.value?.connectivity_?.vesselStatus
})

const spaConnected = computed(() => {
    return spaLinkState.value === VesselConnectionStatus.RUNNING
})

const linkStateIndicatorClass = computed(() => {
    if (wssLinkStateIndicatorClass.value === linkStateColors.UNAVAILABLE) return linkStateColors.UNAVAILABLE
    if (spaLinkStateIndicatorClass.value === linkStateColors.UNAVAILABLE) return linkStateColors.UNAVAILABLE

    if (wssLinkStateIndicatorClass.value === linkStateColors.OFFLINE) return linkStateColors.OFFLINE
    if (spaLinkStateIndicatorClass.value === linkStateColors.OFFLINE) return linkStateColors.OFFLINE

    if (wssLinkStateIndicatorClass.value === linkStateColors.LOADING) return linkStateColors.LOADING
    if (spaLinkStateIndicatorClass.value === linkStateColors.LOADING) return linkStateColors.LOADING
    return linkStateColors.ONLINE
})

const wssLinkStateIndicatorClass = computed(() => {
    if (wssLinkState.value === undefined || wssLinkState.value === GatewayConnectionStatus.UNKNOWN) return linkStateColors.UNAVAILABLE
    if (wssLinkState.value === GatewayConnectionStatus.DISCONNECTED) return linkStateColors.OFFLINE
    if (wssLinkState.value === GatewayConnectionStatus.CONNECTED) return linkStateColors.ONLINE
    return linkStateColors.LOADING
})

const spaLinkStateIndicatorClass = computed(() => {
    if (spaLinkState.value === undefined || spaLinkState.value === VesselConnectionStatus.UNKNOWN) return linkStateColors.UNAVAILABLE
    if (spaLinkState.value === VesselConnectionStatus.RF_DISCONNECTED) return linkStateColors.OFFLINE
    if (spaLinkState.value === VesselConnectionStatus.RF_IN_PAIRING) return linkStateColors.LOADING
    return linkStateColors.ONLINE
})

const showLoading = computed(() => {
    if (vesselClient === undefined) return false
    if (wssLinkStateIndicatorClass.value === linkStateColors.LOADING) return true
    if (spaLinkStateIndicatorClass.value === linkStateColors.LOADING) return true
    if (spaState.value === undefined) return true
    return false
})

const vesselClientIsConnected = computed(() => {
    return vesselClient?.isConnected()
})

const connect = async (accountId: number, monitorId: string) => {
    if (!isInConnection.value && vesselClientIsConnected.value) {
        console.log('Already connected')
        return
    }
    isInConnection.value = true

    inboundMessages.value = []
    outboundMessages.value = []

    const liveStreamSession = await fetchLiveStreamSession(monitorId)
    const mqttSession: MqttSession = {
        ...liveStreamSession,
        url: liveStreamSession.brokerUrl
    }
    const mqttStrategy = new MqttTransporterStrategy(mqttSession, { reconnectPeriod: 1000 * 30 })
    const transporter = new MessageTransporter(mqttStrategy)

    spaConfig = await fetchSpaConfiguration(accountId, monitorId)
    vesselClient = new VesselClient(transporter, spaConfig)


    // Event Handlers
    vesselClient.onUpdate(TransporterEvent.ERROR, socketError)
    vesselClient.onUpdate(TransporterEvent.CONNECTED, onCloudStatusUpdate as TransporterEventCallback)
    vesselClient.onUpdate(TransporterEvent.DISCONNECTED, onCloudStatusUpdate as TransporterEventCallback)
    vesselClient.onUpdate(TransporterEvent.EXPIRED_SESSION, restartConnection)
    vesselClient.onUpdate(VesselStateEvent.STATE_UPDATE, (_event, _state) => {
        onSpaStateUpdate(vesselClient?.getVesselState() as StaticStateVesselV1)
    })

    await vesselClient.connect()
    isInConnection.value = false
}

const restartConnection = async () => {
    if (monitorId.value === undefined) return
    await disconnectFromSpa()
    await connect(props.accountId, monitorId.value)
}

const onSpaStateUpdate = (newSpaState: StaticStateVesselV1) => {
    spaState.value = newSpaState
}

const onCloudStatusUpdate = (event: TransporterEvent) => {
    if (event === TransporterEvent.CONNECTED) {
        cloudConnectionStatus.value = TransporterConnectionStatus.CONNECTED
        snackBarText.value = undefined
    } else if (event === TransporterEvent.DISCONNECTED) {
        cloudConnectionStatus.value = TransporterConnectionStatus.DISCONNECTED
    } else {
        cloudConnectionStatus.value = TransporterConnectionStatus.UNKNOWN
    }
}

const socketError = (reason: string) => {
    snackBarText.value = getErrorEventDetails(reason)
}

const disconnectFromSpa = async () => {
    spaState.value = undefined
    inboundMessages.value = []
    outboundMessages.value = []

    spaConfig = undefined
    await vesselClient?.disconnect()
    vesselClient?.clearListeners()
    vesselClient = undefined
}

const colorForPumpState = (pump: FlowZone): string => {
    if (pump.incrementSpeed === 0) {
        if (pump.active) return 'amber-lighten-2'
        return 'indigo-darken-3'
    } else {
        if (pump.speed === undefined || !pump.active) return 'indigo-darken-3'
        if (pump.speed < pump.maxSpeed) return 'amber-lighten-2'
        if (pump.speed === pump.maxSpeed) return 'orange-darken-2'
    }

    return 'indigo-darken-3'
}

const colorForWaterfallState = (waterfall: FlowZone): string => {
    if (waterfall.active) return 'amber-lighten-2'
    return 'indigo-darken-3'
}

const colorForLightState = (light: LightingZone): string => {
    if (light.active) return 'amber-lighten-1'
    return 'indigo-darken-3'
}

const togglePump = async ({ id, speed, active, maxSpeed, minSpeed, incrementSpeed }: FlowZone) => {
    // TURN ON
    if (active === undefined || active === false || speed === undefined) {
        await vesselClient?.setFlowZoneState(id, { active: true, speed: minSpeed })
        return
    }

    // Flowzone is active here
    if (speed < maxSpeed) {
        await vesselClient?.setFlowZoneState(id, { active: true, speed: Math.min(speed + incrementSpeed, maxSpeed) })
        return
    }

    // TURN OFF
    await vesselClient?.setFlowZoneState(id, { active: false, speed: minSpeed })
}

const toggleWaterfall = async (waterfall: FlowZone) => {
    await vesselClient?.setFlowZoneActivationStatus(waterfall.id, !waterfall.active)
}

const toggleLight = async (light: LightingZone) => {
    await vesselClient?.setLightingZoneActivationStatus(light.id, !light.active)
}

const updateSetPoint = async () => {
    if (heater.value === undefined) throw new Error(`no heater`)
    if (setPointF.value === undefined) throw new Error(`not connected`)
    await vesselClient?.setTemperatureControlZoneSetPoint(heater.value.id, fahrenheitToCelsius(setPointF.value))
}

const getErrorEventDetails = (eventName: string) => {
    switch (eventName) {
        case 'ECONNREFUSED':
            return 'Connection Refused'
        case 'ECONNRESET':
            return 'Connection Reset'
        case 'EADDRINUSE':
            return 'Address In Use'
        case 'ENOTFOUND':
            return 'Not Found'
        default:
            return 'Unknown Error'
    }
}

watch(heaterSetPointC, () => {
    if (heaterSetPointC.value === undefined) return undefined
    setPointF.value = celsiusToFahrenheit(heaterSetPointC.value)
})

watch([monitorId, shouldBeConnected], async (newValue) => {
    const [currentMac, shouldBeConnected] = newValue
    if (props.accountId !== undefined && currentMac != undefined && shouldBeConnected === true && connected.value === false) {
        await connect(props.accountId, currentMac)
    }
})

watch(
    user,
    async (newValue) => {
        if (newValue != undefined && clientIdNeedsGeneration) {
            clientIdNeedsGeneration = false
            if (newValue.email == undefined) throw new Error(`Admin does not have an email address. Check auth0 configuration.`)

            const msgUint8 = new TextEncoder().encode(newValue.email) // encode as (utf-8) Uint8Array
            const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8) // hash the message
            const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
            const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string

            // Client Ids need to be exactly 39 chars (no more, no less)
            const baseId = ('IOS-admin-' + hashHex).substring(0, 39)
            clientId.value = baseId.padEnd(39, '0')
        }
    },
    { immediate: true }
)

</script>

<style lang="scss" scoped>
pre {
    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
    margin-bottom: 10px;

    &:hover {
        background-color: #333;
    }
}

.yellow {
    color: gold;
    font-weight: bolder;
    font-size: 1.2rem;
}

$green: green;
$orange: orange;
$red: red;

.linkStateIndicator {
    width: 30px;
    height: 30px;
    background-color: gray;
    border-radius: 20px;
    display: inline-block;
    margin-left: 16px;

    &.red {
        background-color: $red;
    }

    &.orange {
        background-color: $orange;
    }

    &.green {
        background-color: $green;
    }
}

.linkStateText {
    &.red {
        color: $red;
    }

    &.orange {
        color: $orange;
    }

    &.green {
        color: $green;
    }
}
</style>
