import type { ReadingSourcesV1, ReadingsV1, ReadingTypesV1, ReadingV1 } from '@geckoal/gecko-api-client'

export type ExcludedReadingWithTitle = { title: string; date?: Date; exclusionReason: string; value: number }

export function toReadingsArray(readings?: ReadingsV1, source?: ReadingSourcesV1): ReadingV1[] {
    if (readings === undefined) return []

    const output: ReadingV1[] = []
    for (const readingType of Object.keys(readings) as ReadingTypesV1[]) {
        const reading = readings[readingType]
        if (!reading) continue
        if (source !== undefined && reading.source !== source) continue

        output.push(reading)
    }
    return output
}
