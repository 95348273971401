<template>
    <v-container fluid>
        <v-row v-masonry="containerId" item-selector=".masonryItem" :horizontal-order="true">
            <v-col v-masonry-tile class="masonryItem" md="6">
                <info-card
                    title="Account Spas"
                    :loading="loadingAccountVessels"
                    :is-empty="vessels === undefined || vessels.length === 0"
                    empty-title="No Spas"
                    :min-height="220"
                >
                    <div v-if="vessels" class="vesselCells">
                        <div class="vesselCell" v-for="vessel of vessels" :key="vessel.vesselId">
                            <small-vessel-disc :vessel="vessel" @click="clickedVessel(vessel)" selectable :title="vessel.status?.discElements.text" />
                            <div class="vesselSubTitle" v-if="showLocation">
                                {{ vessel.location?.line1 }}<br />{{ vessel.location?.locality }} {{ vessel.location?.administrativeAreaLevel1 }}
                            </div>
                        </div>
                    </div>
                    <v-btn
                        density="compact"
                        icon="mdi-plus"
                        color="primary"
                        title="Add Vessel"
                        @click="showAddVesselModal = true"
                        class="addVesselButton"
                    ></v-btn>
                </info-card>
            </v-col>

            <v-col v-masonry-tile class="masonryItem" md="6">
                <info-card title="Account Users" :loading="loadingAccountUsers" :is-empty="users === undefined || users.length === 0" empty-title="No Users">
                    <v-expansion-panels variant="accordion" @update:model-value="redrawMasonryAfterDelay">
                        <v-expansion-panel v-for="user of users" :key="user.userId" :hide-actions="true">
                            <v-expansion-panel-title>
                                <v-row no-gutters>
                                    <v-col cols="1" class="d-flex align-center">
                                        <v-avatar color="blue" style="border: 1px solid silver">
                                            <img v-if="user.pictureUrl" :src="user.pictureUrl" />
                                            <span v-else class="white-text text-h5">{{ userInitials(user) }}</span>
                                        </v-avatar>
                                    </v-col>
                                    <v-col class="d-flex align-center">
                                        <h3 class="userTitle ml-2">
                                            <strong>{{ user.firstName }} {{ user.lastName }}</strong> - {{ user.email }}
                                        </h3>
                                        <v-menu>
                                            <template v-slot:activator="{ props }">
                                                <v-btn icon="mdi-dots-vertical" v-bind="props" size="x-small" class="ml-3"></v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item title="Move to Another Account" @click="moveUserToAnotherAccount(user)">
                                                    <template v-slot:prepend>
                                                        <v-icon icon="mdi-account-arrow-right" class="mr-2" color="red"></v-icon>
                                                    </template>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                                <v-list-item
                                                    title="View on Auth0"
                                                    :href="`https://manage.auth0.com/dashboard/us/${auth0Tenant}/users/${user.userId}`"
                                                    target="_auth0"
                                                >
                                                    <template v-slot:prepend>
                                                        <v-icon icon="mdi-shield-star" color="deep-purple-accent-3" class="mr-2"></v-icon>
                                                    </template>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                                <v-list-item title="Copy User ID" @click="copyUserId(user.userId)">
                                                    <template v-slot:prepend>
                                                        <v-icon icon="mdi-content-copy" class="mr-2"></v-icon>
                                                    </template>
                                                </v-list-item>
                                                <v-list-item title="Copy Email" @click="copyEmail(user.email)">
                                                    <template v-slot:prepend>
                                                        <v-icon icon="mdi-content-copy" class="mr-2"></v-icon>
                                                    </template>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                                <v-table density="compact" class="mobileDevices" v-if="user.mobileDevices?.length">
                                    <tbody>
                                        <tr v-for="mobileDevice of user.mobileDevices" :key="mobileDevice.uuid">
                                            <td>
                                                <v-chip v-if="mobileDevice.platform === 'ios'" class="ma-2" color="blue" size="small">iOS</v-chip>
                                                <v-chip v-else-if="mobileDevice.platform === 'android'" class="ma-2" color="green" size="small">Android</v-chip>
                                            </td>
                                            <td>{{ mobileDevice.name }}</td>
                                            <td>{{ mobileDevice.model }} ({{ mobileDevice.os }} {{ mobileDevice.osVersion }})</td>
                                            <td class="text-right">
                                                <v-icon
                                                    v-if="mobileDevice.snsEndpointArn"
                                                    icon="mdi-bell-ring"
                                                    size="x-small"
                                                    color="warning"
                                                    class="mr-2"
                                                    title="Notifications Enabled"
                                                />
                                                <v-menu>
                                                    <template v-slot:activator="{ props }">
                                                        <v-btn icon="mdi-dots-vertical" v-bind="props" size="x-small"></v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item
                                                            v-if="mobileDevice.snsEndpointArn"
                                                            title="Send Push Notification"
                                                            @click="showPushNotificationDialog(user, mobileDevice)"
                                                        >
                                                            <template v-slot:prepend>
                                                                <v-icon icon="mdi-bell-ring" color="warning" class="mr-2" />
                                                            </template>
                                                        </v-list-item>
                                                        <v-divider v-if="mobileDevice.snsEndpointArn"></v-divider>
                                                        <v-list-item
                                                            v-if="mobileDevice.snsEndpointArn"
                                                            title="View on SNS"
                                                            :href="`https://us-east-1.console.aws.amazon.com/sns/v3/home?region=us-east-1#/mobile/endpoint/${mobileDevice.snsEndpointArn}`"
                                                            target="_aws"
                                                        >
                                                            <template v-slot:prepend>
                                                                <v-icon icon="mdi-aws" color="warning" class="mr-2" />
                                                            </template>
                                                        </v-list-item>
                                                        <v-list-item title="Copy Device ID" @click="copyDeviceId(mobileDevice.uuid)">
                                                            <template v-slot:prepend>
                                                                <v-icon icon="mdi-content-copy" class="mr-2"></v-icon>
                                                            </template>
                                                        </v-list-item>
                                                        <v-divider></v-divider>
                                                        <v-list-item title="Delete Device" @click="confirmDeleteDevice(mobileDevice)">
                                                            <template v-slot:prepend>
                                                                <v-icon icon="mdi-delete" class="mr-2" color="error"></v-icon>
                                                            </template>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-table>
                                <p v-else>No Mobile Devices</p>
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </info-card>
            </v-col>

            <v-col v-masonry-tile class="masonryItem" md="6">
                <info-card
                    title="Feedback"
                    :min-height="120"
                    :loading="loadingRecentFeedback"
                    :is-empty="recentFeedback === undefined || recentFeedback.length === 0"
                    empty-title="No Feedback"
                >
                    <v-table class="feedback" v-if="recentFeedback && recentFeedback.length > 0">
                        <thead>
                            <tr>
                                <th>Date / Rating</th>
                                <th>Text</th>
                                <th>Ticket</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(feedback, index) of recentFeedback" :key="index">
                                <td>
                                    <strong>{{ format(feedback.date, 'Pp') }}</strong
                                    ><br />
                                    <v-rating v-model="feedback.rating" readonly density="compact" color="grey-lighten-1" active-color="yellow-accent-4" />
                                </td>
                                <td>
                                    <span v-if="feedback.feedbackText">"{{ feedback.feedbackText }}"</span>
                                </td>
                                <td>
                                    <v-btn v-if="feedback.ticketId !== undefined" variant="text" color="primary" :href="feedback.ticketUrl" target="_freshdesk"
                                        >#{{ feedback.ticketId }}</v-btn
                                    >
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </info-card>
            </v-col>

            <v-col v-masonry-tile class="masonryItem" md="6">
                <account-messages :account-id="accountId" @messages-updated="redrawMasonry" />
            </v-col>

            <!-- v-col v-masonry-tile class="masonryItem" md="6">
                <info-card title="Subscriptions" :min-height="120" :loading="loadingAccountUsers" empty-title="No Subscriptions">
                    <v-table class="subscriptions" density="compact">
                        <thead>
                            <tr>
                                <th>Vessel</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Status</th>
                                <th>Collection Paused</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="accountDetails?.subscriptions !== undefined && accountDetails.subscriptions.length === 0">
                                <td colspan="6" class="text-center">No Subscriptions</td>
                            </tr>
                            <tr v-else v-for="subscription of accountDetails?.subscriptions" :key="subscription.subscriptionId" class="subscriptionRow">
                                <td>
                                    <vessel-button v-if="subscription.vessel" :vessel="subscription.vessel" size="small" />
                                </td>
                                <td>
                                    <strong>{{ format(subscription.startDate, 'Pp') }}</strong>
                                </td>
                                <td>
                                    <strong v-if="subscription.endDate">{{ format(subscription.endDate, 'Pp') }}</strong>
                                </td>
                                <td>
                                    <subscription-status-chip :subscription="subscription" />
                                </td>
                                <td>
                                    {{ subscription.collectionPaused }}
                                </td>
                                <td>
                                    <stripe-button :href="subscription.stripeSubscriptionUrl" />
                                </td>
                            </tr>
                            <tr v-if="addPaymentMethodBanner !== undefined">
                                <td colspan="6" class="text-center">
                                    <v-chip variant="flat" color="orange" style="margin: 20px 0"
                                        ><v-icon start icon="mdi-credit-card-outline" /> NO PAYMENT METHOD</v-chip
                                    >
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn icon="mdi-dots-vertical" v-bind="props" class="subscriptionsMenuButton" density="compact"></v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-if="accountDetails?.stripeCustomerUrl"
                                :href="accountDetails?.stripeCustomerUrl"
                                target="_stripe"
                                button
                                title="View Customer On Stripe"
                            >
                                <template v-slot:prepend>
                                    <v-icon icon="mdi-card-account-details" class="mr-2"></v-icon>
                                </template>
                            </v-list-item>
                            <v-list-item
                                v-if="accountDetails?.stripeCustomerUrl"
                                button
                                title="Sync Subscriptions With Stripe"
                                @click="syncStripeSubscriptions"
                            >
                                <template v-slot:prepend>
                                    <v-icon icon="mdi-sync" class="mr-2"></v-icon>
                                </template>
                            </v-list-item>
                            <v-list-item
                                v-if="accountDetails?.stripeCustomerUrl"
                                button
                                title="Copy Payment Method Update URL"
                                @click="copyPaymentMethodUpdateUrl"
                            >
                                <template v-slot:prepend>
                                    <v-icon icon="mdi-content-copy" class="mr-2"></v-icon>
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </info-card>
            </v-col> -->

            <v-col v-masonry-tile class="masonryItem" md="6">
                <info-card
                    title="Tickets"
                    :min-height="120"
                    :loading="loadingRecentTickets"
                    :is-empty="recentTickets === undefined || recentTickets.length === 0"
                    empty-title="No Tickets"
                >
                    <v-table class="feedback" density="compact">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Date</th>
                                <th>Subject</th>
                                <th>Status</th>
                                <th>Priority</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="ticket of recentTickets" :key="ticket.ticketId" @click="showTicket(ticket)" class="ticketRow">
                                <td>
                                    <v-btn variant="text" color="primary" @click="viewTicket(ticket, $event)" :href="ticket.freshdeskUrl" target="_freshdesk"
                                        >#{{ ticket.ticketId }}</v-btn
                                    >
                                </td>
                                <td>
                                    <strong>{{ format(ticket.createdAt, 'Pp') }}</strong>
                                </td>
                                <td>{{ ticket.subject }}</td>
                                <th>
                                    <ticket-status-chip :ticket="ticket" />
                                </th>
                                <th>
                                    <ticket-priority-chip :ticket="ticket" />
                                </th>
                            </tr>
                        </tbody>
                    </v-table>
                </info-card>
            </v-col>

            <v-col v-masonry-tile class="masonryItem" md="6">
                <info-card
                    title="Account Monitors"
                    :min-height="120"
                    :loading="loadingAccountMonitors"
                    :is-empty="accountMonitors === undefined || accountMonitors.length === 0"
                    empty-title="No Monitors"
                >
                    <v-table class="monitors" density="compact">
                        <thead>
                            <tr>
                                <th>Monitor ID</th>
                                <th>Vessel</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="monitor of accountMonitors" :key="monitor.monitorId">
                                <td>
                                    <v-btn variant="text" color="blue-darken-1" :to="{ name: 'MonitorDetails', params: { monitorId: monitor.monitorId } }">{{
                                        monitor.monitorId
                                    }}</v-btn>
                                </td>
                                <td>
                                    <span v-if="monitor.accountId && monitor.vessel">
                                        <!-- Note, we should just add the accountId to the vessel from the API route -->
                                        <vessel-button :vessel="{ ...monitor.vessel, accountId: monitor.accountId }" size="small" />
                                    </span>
                                </td>
                                <td class="text-right">
                                    <v-btn
                                        @click="confirmRemoveMonitorFromAccount(monitor.monitorId)"
                                        v-if="monitor.vessel === undefined"
                                        size="x-small"
                                        color="red"
                                        variant="outlined"
                                        prepend-icon="mdi-delete"
                                        >Remove</v-btn
                                    >
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </info-card>
            </v-col>

            <v-col v-masonry-tile class="masonryItem" md="6">
                <info-card
                    title="Account Chemicals"
                    :min-height="120"
                    :loading="loadingAccountChemicals"
                    :is-empty="chemicals === undefined || chemicals.length === 0"
                    empty-title="No Chemicals"
                >
                    <v-table class="chemicals" density="compact">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Type - Percent</th>
                                <th>Barcode</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="chemical of chemicals" :key="chemical.id.accountChemicalId">
                                <td><v-img v-if="chemical.imageUrl" :src="chemical.imageUrl" class="chemicalImage" /></td>
                                <td>{{ chemical.name }}</td>
                                <td>
                                    {{ formatIngredientName(chemical)
                                    }}<span v-if="chemical.chemicalPercentage !== undefined"> - {{ chemical.chemicalPercentage }}%</span>
                                </td>
                                <td>{{ formatBarcode(chemical) }}</td>
                                <td class="text-right">
                                    <v-menu>
                                        <template v-slot:activator="{ props }">
                                            <v-btn icon="mdi-dots-vertical" variant="text" v-bind="props"></v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item title="Dose Chemical" @click="doseChemical(chemical)">
                                                <template v-slot:prepend>
                                                    <v-icon icon="mdi-barcode"></v-icon>
                                                </template>
                                            </v-list-item>
                                            <v-list-item title="Copy Account Chemical ID" @click="copyAccountChemicalIdToClipboard(chemical)">
                                                <template v-slot:prepend>
                                                    <v-icon icon="mdi-content-copy"></v-icon>
                                                </template>
                                            </v-list-item>
                                            <v-divider />
                                            <v-list-item @click="confirmDeleteChemical(chemical)" title="Delete Account Chemical" class="text-red-darken-2">
                                                <template v-slot:prepend>
                                                    <v-icon color="error" icon="mdi-delete"></v-icon>
                                                </template>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </info-card>
            </v-col>
        </v-row>
    </v-container>

    <dose-chemical-modal v-model="showDoseChemicalModal" :account-id="accountId" :chemical="selectedChemical" />

    <add-vessel-modal :account-id="accountId" @vessel-added="vesselAdded" v-model="showAddVesselModal" />

    <v-dialog v-model="showConfirmRemoveFromAccountDialog" max-width="500px">
        <v-card>
            <v-card-title>Remove '{{ monitorIdToRemove }}' from this account?</v-card-title>
            <v-card-text> This monitor will be unclaimed meaning a user from another account will be able to claim and set up this monitor. </v-card-text>
            <v-card-actions>
                <v-btn color="grey-lighten-1" @click="showConfirmRemoveFromAccountDialog = false">No, Cancel</v-btn>
                <v-btn color="error" @click="removeMonitorFromAccount">Yes, Remove From This Account</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="showSendPushNotificationsDialog">
        <v-card :min-width="500">
            <v-card-title> Send Push Notification to: {{ selectedMobileDevice?.mobileDevice.uuid }} </v-card-title>
            <v-card-text>
                <v-list>
                    <v-list-item>
                        <v-text-field v-model="pushNotificationTitle" label="Title" required></v-text-field>
                    </v-list-item>
                    <v-list-item>
                        <v-text-field v-model="pushNotificationBody" label="Message Body" required></v-text-field>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-btn color="secondary" @click="showSendPushNotificationsDialog = false">Cancel</v-btn>
                <v-btn color="primary" @click="sendNotification()">SEND NOTIFICATION</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <support-ticket-details-modal v-model="showTicketDescription" :ticket="selectedTicket" />

    <v-snackbar v-model="showCopiedUserIdSnackBar" color="success" :timeout="3000"> User ID copied to your clipboard </v-snackbar>
    <v-snackbar v-model="showCopiedEmailSnackBar" color="success" :timeout="3000"> User Email copied to your clipboard </v-snackbar>
    <v-snackbar v-model="showCopiedDeviceIdSnackBar" color="success" :timeout="3000"> Device ID copied to your clipboard </v-snackbar>

    <v-snackbar v-model="showPushNotificationSnackbar" :timeout="3000" :color="pushNotificationSnackbarColor">
        {{ pushNotificationSnackbarText }}
    </v-snackbar>

    <v-snackbar v-model="showCopiedAccountChemicalSnackbar" multi-line color="success" :timeout="3000">{{ copiedAccountChemicalIdSnackbarText }}</v-snackbar>

    <move-user-modal v-model="showMoveUserModal" :account-id="accountId" :user="userToMove" />

    <!-- <v-dialog v-model="showSyncSubscriptionsModal">
        <v-card v-if="syncSubscriptionsResults">
            <v-card-text>
                <v-row>
                    <v-col><h2>Sync Results</h2></v-col>
                </v-row>
                <v-row v-for="result of syncSubscriptionsResults" :key="result">
                    <v-col>{{ result }}</v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog> -->

    <v-dialog v-model="showConfirmDeleteMobileDeviceDialog" max-width="800">
        <v-card>
            <v-card-title>Are you sure you want to delete this mobile device?</v-card-title>
            <v-card-text>The user will not be able to receive push notifications.</v-card-text>
            <v-card-actions>
                <v-btn color="red-darken-1" :loading="deletingMobileDevice" @click="deleteMobileDevice">YES, DELETE IT</v-btn>
                <v-btn color="gray-darken-1" @click=";(showConfirmDeleteMobileDeviceDialog = false), (mobileDeviceToDelete = undefined)">NO, CANCEL</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="showConfirmDeleteChemicalDialog" max-width="800">
        <v-card>
            <v-card-title>Are you sure you want to delete this account chemical?</v-card-title>
            <v-card-text>It will be removed from any active slots and the user will be required to re-create it to use this product in the future.</v-card-text>
            <v-card-actions>
                <v-btn color="red-darken-1" :loading="deletingChemical" @click="deleteChemical">YES, DELETE IT</v-btn>
                <v-btn color="gray-darken-1" @click=";(showConfirmDeleteChemicalDialog = false), (chemicalToDelete = undefined)">NO, CANCEL</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import {
    type AddPaymentMethodBannerV1,
    type AccountDetailsV1,
    type AccountMonitorV1,
    type AccountTicketV1,
    type AccountUserV1,
    type AccountVesselV1,
    type ChemicalV2,
    type MobileDeviceV1
} from '@geckoal/gecko-api-client'
import { ref, onMounted, computed, watch, inject } from 'vue'
import '@/components/VesselDisc.vue'
import SmallVesselDisc from '../components/SmallVesselDisc.vue'
import { useRouter } from 'vue-router'
import { useBreadCrumbsStore } from '@/stores/breadCrumbs'
import { useApi } from '@/api'
import { CUSTOM_ACTIONS_VERSION, handleApiError, userInitials } from '@/lib/utils'
import { copyToClipboard } from '@/lib/utils'
import AddVesselModal from '@/components/vessels/AddVesselModal.vue'
import VesselButton from '@/components/buttons/VesselButton.vue'
import DoseChemicalModal from '@/components/chemicals/DoseChemicalModal.vue'
import MoveUserModal from '@/components/accounts/MoveUserModal.vue'
import { format } from 'date-fns'
import { useAccountStore } from '@/stores/account'
// import StripeButton from '@/components/buttons/StripeButton.vue'
import SupportTicketDetailsModal from '@/components/tickets/SupportTicketDetailsModal.vue'
import TicketStatusChip from '@/components/tickets/TicketStatusChip.vue'
import TicketPriorityChip from '@/components/tickets/TicketPriorityChip.vue'
import AccountMessages from '@/components/accounts/AccountMessages.vue'
import InfoCard from '@/components/InfoCard.vue'
// import SubscriptionStatusChip from '@/components/subscriptions/SubscriptionStatusChip.vue'
import { formatBarcode, formatIngredientName } from '@/lib/chemicals'

const breadCrumbsStore = useBreadCrumbsStore()
const router = useRouter()
const accountStore = useAccountStore()

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    }
})

onMounted(() => {
    _loadInfo()
})

watch(
    () => props.accountId,
    () => {
        _loadInfo()
    }
)

const containerId = 'accountItems'

const redrawVueMasonry = inject('redrawVueMasonry') as (id: string) => void

const redrawMasonry = () => {
    setTimeout(() => {
        redrawVueMasonry(containerId)
    }, 100)
}

const redrawMasonryAfterDelay = () => setTimeout(redrawMasonry, 300)

const showSendPushNotificationsDialog = ref(false)
const selectedMobileDevice = ref<{ user: AccountUserV1; mobileDevice: MobileDeviceV1 } | undefined>()
const pushNotificationTitle = ref('Gecko - Test Message')
const pushNotificationBody = ref('Gecko push notifications are working properly')
const showPushNotificationSnackbar = ref(false)
const showCopiedDeviceIdSnackBar = ref(false)
const showCopiedUserIdSnackBar = ref(false)
const showCopiedEmailSnackBar = ref(false)

const pushNotificationSnackbarColor = ref('green')
const pushNotificationSnackbarText = ref<string | undefined>()
const showCopiedAccountChemicalSnackbar = ref(false)
const copiedAccountChemicalIdSnackbarText = ref<string | undefined>()
const showAddVesselModal = ref(false)
const auth0Tenant = ref(import.meta.env.VITE_AUTH0_TENANT_NAME)
const showConfirmRemoveFromAccountDialog = ref(false)
const monitorIdToRemove = ref<string | undefined>()

const users = ref<AccountUserV1[] | undefined>()
const vessels = ref<AccountVesselV1[] | undefined>()
const chemicals = ref<ChemicalV2[] | undefined>()
const accountMonitors = ref<AccountMonitorV1[] | undefined>()
const addPaymentMethodBanner = ref<AddPaymentMethodBannerV1 | undefined>()

const loadingAccountUsers = ref(false)
const loadingAccountVessels = ref(false)
const loadingAccountChemicals = ref(false)
const loadingAccountMonitors = ref(false)

const showDoseChemicalModal = ref(false)
const selectedChemical = ref<ChemicalV2 | undefined>()

const userToMove = ref<AccountUserV1 | undefined>()
const showMoveUserModal = ref(false)

const showTicketDescription = ref(false)
const selectedTicket = ref<AccountTicketV1 | undefined>()

const accountDetails = ref<AccountDetailsV1 | undefined>()

// const showSyncSubscriptionsModal = ref(false)
// const syncSubscriptionsResults = ref<string[] | undefined>()

const showConfirmDeleteMobileDeviceDialog = ref(false)
const deletingMobileDevice = ref(false)
const mobileDeviceToDelete = ref<MobileDeviceV1>()

// const copyPaymentMethodUpdateUrl = async () => {
//     handleApiError(async () => {
//         const result = await useApi().postStripeUpdatePaymentMethodPortalSessionV1({
//             accountId: props.accountId,
//             postStripePaymentMethodUpdatePortalSessionV1Body: {}
//         })
//         copyToClipboard(result.stripePaymentMethodUpdatePortalUrl)
//     })
// }

const confirmDeleteDevice = (device: MobileDeviceV1) => {
    mobileDeviceToDelete.value = device
    showConfirmDeleteMobileDeviceDialog.value = true
}

const deleteMobileDevice = () => {
    deletingMobileDevice.value = true
    handleApiError(
        async () => {
            if (mobileDeviceToDelete.value?.uuid === undefined) return

            await useApi().deleteMobileDeviceV2({
                accountId: props.accountId,
                // Should be 'gecko' for now, but we should get this from the user's session of whatever
                appId: 'gecko',
                mobileDeviceUuid: mobileDeviceToDelete.value.uuid
            })

            await getAccountDetails()

            mobileDeviceToDelete.value = undefined
            showConfirmDeleteMobileDeviceDialog.value = false
        },
        async () => {
            deletingMobileDevice.value = false
        }
    )
}

const showTicket = (ticket: AccountTicketV1) => {
    selectedTicket.value = ticket
    showTicketDescription.value = true
}

const moveUserToAnotherAccount = (user: AccountUserV1) => {
    userToMove.value = user
    showMoveUserModal.value = true
}

const getAccountMonitors = async () => {
    await handleApiError(
        async () => {
            loadingAccountMonitors.value = true
            const response = await useApi().getAccountMonitors({ accountId: props.accountId })
            accountMonitors.value = response.monitors
            redrawMasonry()
        },
        async () => {
            loadingAccountMonitors.value = false
        }
    )
}

const doseChemical = (chemical: ChemicalV2) => {
    selectedChemical.value = chemical
    showDoseChemicalModal.value = true
}

const showConfirmDeleteChemicalDialog = ref(false)
const deletingChemical = ref(false)
const chemicalToDelete = ref<ChemicalV2>()

const confirmDeleteChemical = (chemical: ChemicalV2) => {
    chemicalToDelete.value = chemical
    showConfirmDeleteChemicalDialog.value = true
}

const deleteChemical = () => {
    deletingChemical.value = true
    handleApiError(
        async () => {
            if (chemicalToDelete.value?.id.accountChemicalId === undefined) return

            await useApi().deleteAccountChemicalV1({
                accountId: props.accountId,
                accountChemicalId: chemicalToDelete.value?.id.accountChemicalId
            })

            await getAccountChemicals()
            chemicalToDelete.value = undefined
            showConfirmDeleteChemicalDialog.value = false
        },
        async () => {
            deletingChemical.value = false
        }
    )
}

const getAccountDetails = async () => {
    await handleApiError(
        async () => {
            loadingAccountUsers.value = true
            const response = await useApi().getAccountDetailsV1({ accountId: props.accountId })
            users.value = response.users
            accountDetails.value = response
            redrawMasonry()
        },
        async () => {
            loadingAccountUsers.value = false
        }
    )
}

const getAccountVessels = async () => {
    await handleApiError(
        async () => {
            loadingAccountVessels.value = true
            const response = await useApi().getAccountVesselsV3({
                accountId: props.accountId,
                customActionsVersion: CUSTOM_ACTIONS_VERSION
            })
            vessels.value = response.vessels
            addPaymentMethodBanner.value = response.addPaymentMethodBanner
            redrawMasonry()
        },
        async () => {
            loadingAccountVessels.value = false
        }
    )
}

const getAccountChemicals = async () => {
    await handleApiError(
        async () => {
            loadingAccountChemicals.value = true
            const response = await useApi().getAccountChemicalsV2({ accountId: props.accountId })
            chemicals.value = response.chemicals
            redrawMasonry()
        },
        async () => {
            loadingAccountChemicals.value = false
        }
    )
}

async function _loadInfo() {
    breadCrumbsStore.$patch({
        items: [
            {
                text: `Account #${props.accountId}`
            }
        ]
    })
    getAccountDetails()
    getAccountVessels()
    getAccountChemicals()
    getAccountMonitors()
    await accountStore.loadRecentFeedback(props.accountId)
    await accountStore.loadRecentTickets(props.accountId)
    redrawMasonry()
}

const loadingRecentFeedback = computed(() => {
    return accountStore.$state.loadingRecentFeedback
})

const recentFeedback = computed(() => {
    return accountStore.$state.recentFeedback
})

const loadingRecentTickets = computed(() => {
    return accountStore.$state.loadingRecentTickets
})

const recentTickets = computed(() => {
    return accountStore.$state.recentTickets
})

const clickedVessel = (vessel: AccountVesselV1) => {
    router.push({ name: 'VesselDetails', params: { accountId: vessel.accountId, vesselId: vessel.vesselId } })
}

const copyAccountChemicalIdToClipboard = (chemical: ChemicalV2) => {
    copyToClipboard(String(chemical.id.accountChemicalId))
    copiedAccountChemicalIdSnackbarText.value = `Copied account chemical id #${chemical.id.accountChemicalId} to your clipboard`
    showCopiedAccountChemicalSnackbar.value = true
}

function allAddressesExactlyEqual(vessels: AccountVesselV1[]): boolean {
    const addressStrings = new Set()
    for (const vessel of vessels) {
        if (vessel.location) {
            addressStrings.add(JSON.stringify(vessel.location))
        }
    }
    return addressStrings.size <= 1
}

const showLocation = computed(() => {
    if (!vessels.value) return false
    return !allAddressesExactlyEqual(vessels.value)
})

const showPushNotificationDialog = (user: AccountUserV1, mobileDevice: MobileDeviceV1) => {
    selectedMobileDevice.value = {
        user,
        mobileDevice
    }
    showSendPushNotificationsDialog.value = true
}

const sendNotification = async () => {
    if (!selectedMobileDevice.value) return
    const { user, mobileDevice } = selectedMobileDevice.value
    try {
        console.log('SENDING NOTIFICATION', user, mobileDevice)
        const result = await useApi().putPushNotificationV1({
            accountId: props.accountId,
            userId: user.userId,
            uuid: mobileDevice.uuid,
            sendPushNotificationsBodyV1: {
                notification: {
                    title: pushNotificationTitle.value,
                    body: pushNotificationBody.value
                }
            }
        })
        console.log('SENT NOTIFICATION', result)

        if (result.sent) {
            pushNotificationSnackbarText.value = 'Sent push notification'
            pushNotificationSnackbarColor.value = 'green'
            showPushNotificationSnackbar.value = true
        } else {
            pushNotificationSnackbarText.value = `Error sending push notification: ${result.error ?? 'unknown error'}`
            pushNotificationSnackbarColor.value = 'red'
            showPushNotificationSnackbar.value = true
        }
    } catch (error) {
        console.error('ERROR SENDING NOTIFICATION', error)
        pushNotificationSnackbarText.value = JSON.stringify(error)
        pushNotificationSnackbarColor.value = 'red'
        showPushNotificationSnackbar.value = true
    } finally {
        showSendPushNotificationsDialog.value = false
    }
}

const vesselAdded = () => {
    getAccountVessels()
}

const confirmRemoveMonitorFromAccount = (monitorId: string) => {
    monitorIdToRemove.value = monitorId
    showConfirmRemoveFromAccountDialog.value = true
}

const removeMonitorFromAccount = () => {
    showConfirmRemoveFromAccountDialog.value = false
    if (monitorIdToRemove.value === undefined) return

    handleApiError(async () => {
        await useApi().deleteAccountMonitor({
            accountId: props.accountId,
            monitorId: monitorIdToRemove.value! // eslint-disable-line @typescript-eslint/no-non-null-assertion
        })
        await getAccountMonitors()
    })
}

const copyDeviceId = (deviceId: string) => {
    copyToClipboard(deviceId)
    showCopiedDeviceIdSnackBar.value = true
}

const copyUserId = (userId: string) => {
    copyToClipboard(userId)
    showCopiedUserIdSnackBar.value = true
}

const copyEmail = (email: string) => {
    copyToClipboard(email)
    showCopiedEmailSnackBar.value = true
}

const viewTicket = (ticket: AccountTicketV1, event: Event) => {
    window.open(ticket.freshdeskUrl, '_freshdesk')
    event.stopImmediatePropagation()
}

// const syncStripeSubscriptions = () => {
//     handleApiError(async () => {
//         if (props.accountId === undefined) return

//         const results = await useApi().postSyncStripeSubscriptionsV1({
//             accountId: props.accountId
//         })

//         syncSubscriptionsResults.value = results.results
//         showSyncSubscriptionsModal.value = true
//     })
// }
</script>

<style lang="scss" scoped>
.vesselCells {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .vesselCell {
        margin: 0 20px 20px 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        font-size: 12px;
        font-weight: 400;

        flex-grow: 0;

        .vesselSubTitle {
            font-size: 12px;
            color: #444;
            text-align: center;
        }
    }
}

.users {
    tr.userRow {
        background-color: rgba(0, 0, 0, 0.1);

        td {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}

.chemicals {
    tr {
        td {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}

.subscriptionsMenuButton {
    position: absolute;
    top: 6px;
    right: 6px;
}

.chemicalImage {
    width: 50px;
    height: auto;
    margin: 5px;
}

.userTitle {
    font-weight: 400;
}

.ticketDescription {
    padding: 10px;
    border: 1px solid #eee;
}

.ticketRow {
    cursor: pointer;
    &:hover {
        background-color: rgb(var(--v-theme-hover-background));
    }
}

.masonryItem {
    margin-bottom: 10px;
    padding: 10px;
}

.v-expansion-panels--variant-accordion > :last-child {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}

.v-expansion-panels--variant-accordion > :first-child {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.addVesselButton {
    position: absolute;
    top: 6px;
    right: 6px;
}
</style>
